export function useAvailableEnergy() {
  const { usableEnergy } = useAppState()
  const rentableColumns = ref([
    {
      title: $t('VDbiV35z7tQmGihnu5xza'),
      dataIndex: 'Time',
      align: 'left',
    },
    {
      title: $t('hRcSrbvS4FCufVdi7drVr'),
      dataIndex: 'Energy',
      align: 'right',
    },
  ])

  const rentableData = computed(() => {
    const arr: any[] = []
    if (!usableEnergy.value?.energy_available)
      return

    for (const [key, value] of Object.entries(usableEnergy.value?.energy_available)) {
      const hour = Number(key.slice(0, -1)) > 1 ? $t('GRAxQVNTjLwXYfOpY6FRh') : $t('-VXE8U3ypH51xAowQGH8d')
      const day = Number(key.slice(0, -1)) > 1 ? `${$t('8zE4c0R_g37KkcLgrn4u0')}` : $t('sgq3K4HMvzvjpCGigTbMu')
      let data: any = {
        Time: key
          .replace('d', day)
          .replace('h', hour)
          .replace('m', $t('64lVMNqc_1lucK0740Yu4')),
        Energy: formatAmount(value, 0),
      }

      if (Number(key.slice(0, -1)) === 3 && key.includes('d')) {
        data = {
          Time: `≥ ${key
          .replace('d', day)
          .replace('h', hour)
          .replace('m', $t('64lVMNqc_1lucK0740Yu4'))}`,
          Energy: formatAmount(value, 0),
        }
      }

      if (Number(key.slice(0, -1)) === 1 && key.includes('h')) {
        const max = value
        data = {
          Time: `${key
          .replace('d', day)
          .replace('h', hour)
          .replace('m', $t('64lVMNqc_1lucK0740Yu4'))}`,
          Energy: formatAmount(max, 0),
        }
      }

      arr.push(data)
    }
    return arr
  })

  const deilColumns = ref([
    {
      title: $t('o6p7VpUiEs7gwCZHZdVnd'),
      dataIndex: 'Time',
      align: 'left',
      width: 135,
    },
    {
      title: $t('Mqe_hlD_yGPsAMB9oaO1D'),
      dataIndex: 'Energy',
      align: 'right',
      width: 135,
    },
  ])

  const deilData = computed(() => {
    const arr: any[] = []
    if (!usableEnergy.value?.energy_freed)
      return

    for (const [key, value] of Object.entries(usableEnergy.value?.energy_freed)) {
      const str = Number(key.slice(0, -1)) > 1 ? $t('GRAxQVNTjLwXYfOpY6FRh') : $t('-VXE8U3ypH51xAowQGH8d')
      arr.push({
        Time: key
          .replace('h', str)
          .replace('m', $t('64lVMNqc_1lucK0740Yu4')),
        Energy: formatAmount(value, 0),
      })
    }
    return arr
  })

  return {
    rentableColumns,
    rentableData,
    deilColumns,
    deilData,
  }
}
