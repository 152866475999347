export function getSun(rentDuration: number, rentTimeUnit: string): number {
  const { config } = useAppState()
  const priceB2c = config?.value?.price_b2c
  const increase_sun = config?.value?.agent.increase_sun
  const energy_price = config.value?.estimate.energy_price ?? 210

  if (!priceB2c)
    return 0
  if (rentDuration < 1 || rentDuration > 30)
    return 0

  let sun
  sun = rentTimeUnit === 'h' ? priceB2c.energy.sun_1h : priceB2c.energy.sun
  if(rentTimeUnit === 'm' && !!priceB2c.energy.sun_m){
    sun = priceB2c.energy.sun_m
  }

  const algorithmMap: { [key: string]: number } = {
    '1_h': priceB2c.energy.sun_1h,
    '3_h': priceB2c.energy.sun_3h,
    '1_d': priceB2c.energy.sun_1d,
    '2_d': priceB2c.energy.sun_2d,
    '10_m': priceB2c.energy.sun_m,
  }
  const key = `${rentDuration}_${rentTimeUnit}`

  sun = algorithmMap[key] || sun
  
  if (increase_sun && increase_sun > 0 && increase_sun < energy_price)
    return sun + increase_sun

  return sun
}
