import { getSun } from '~/composables/app/getSun'
import BigNumber, { default as bignumber } from 'bignumber.js';

interface Params {
  resourceValue: number
  rentDuration: number
  rentTimeUnit: string
}
interface Result {
  payAmount: BigNumber;
  serviceAmount: BigNumber;
  sun: BigNumber;
}

export function getPayAmountByResource(params: Params): Result {
  const { config } = useAppState()
  
  console.log('params',params);
  
  const result: Result = {
    payAmount: bignumber(0),
    serviceAmount: bignumber(0),
    sun: bignumber(0),
  }
  
  result.sun = bignumber(getSun(params.rentDuration, params.rentTimeUnit));
  
  if (params.rentTimeUnit === 'h' || params.rentTimeUnit === 'm') {
    // 小时的计算方式
    result.payAmount = bignumber(params.resourceValue).multipliedBy(result.sun).dividedBy(1000000);
  }
  else if (params.rentTimeUnit === 'd') {
    // 天的计算方式
    if (params.rentDuration < 1 || params.rentDuration > 30)
      return result
    
    result.payAmount = bignumber(params.resourceValue)
    .multipliedBy(result.sun)
    .dividedBy(1000000)
    .multipliedBy(params.rentDuration);
  }
  
  const serviceAmountLimit = bignumber(config.value?.price_b2c.energy?.service_amount_limit || 0);
  
  if (bignumber(params.resourceValue).isLessThan(serviceAmountLimit)) {
    result.serviceAmount = bignumber(config.value?.price_b2c.energy?.service_amount || 0);
    result.payAmount = result.payAmount.plus(result.serviceAmount);
  }
  
  return result
}
