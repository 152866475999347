import range from 'lodash-es/range'

export { useRentDuration }

function useRentDuration() {
  const { config } = useAppState()
  const validValues: { dur: number; unit: 'h' | 'd'| 'm' }[] = [
    { dur: 1, unit: 'h' },
    { dur: 10, unit: 'm' },
    { dur: 3, unit: 'h' },
    ...(range(1, 30).map(v => ({ dur: v, unit: 'd' })) as any),
  ]
  const duration = ref(1)
  const unit = ref<'h' | 'd' | 'm'>('h')
  const shortcutsValue = ref([validValues[0], validValues[1], validValues[2], validValues[4]])
  const shortcutsLabel = computed(() => {
    return shortcutsValue.value.map(item => ({
      dur: item.dur,
      unit: item.unit,
      text: item.dur + item.unit.replace('h', $t('GRAxQVNTjLwXYfOpY6FRh')).replace('d', $t('8zE4c0R_g37KkcLgrn4u0')),
    }))
  })
  
  const currentIndex = ref(0)
  const currentValue = computed(() => validValues[currentIndex.value])
  
  const find = (params: typeof validValues[0]) =>
    validValues.findIndex(item => item.dur + item.unit === params.dur + params.unit)
  const increase = () => {
    if (currentIndex.value < validValues.length - 1)
      currentIndex.value++
  }
  const decrease = () => {
    if (currentIndex.value > 0)
      currentIndex.value--
  }
  const change = (params: typeof validValues[0]) => {
    const index = find(params)
    currentIndex.value = index
  }
  const isCurrent = (params: typeof validValues[0]) => {
    return params.dur + params.unit === unref(duration) + unref(unit)
  }
  
  watch(
    currentValue,
    (value) => {
      duration.value = value.dur
      unit.value = value.unit
    },
    { immediate: true },
  )
  
  watch(duration, (dur, oldDur) => {
    if (!dur || dur === oldDur)
      return
    
    const _unit = unref(unit)
    // 优先查找当前时间级下的时长
    let index = find({ dur, unit: _unit })
    
    // FIXME: 如果加入分钟（m）者可能出现bug，原因：如果“分钟”和"天"都有时长5，那最终应该选哪个？两者的优先级怎么定？
    if (index === -1 && _unit !== 'm')
      index = find({ dur, unit: 'm' })
    if (index === -1 && _unit !== 'h')
      index = find({ dur, unit: 'h' })
    if (index === -1 && _unit !== 'd')
      index = find({ dur, unit: 'd' })
    
    if (index !== -1)
      currentIndex.value = index
  })
  
  const second = ref(3600)
  
  watch(config,(val)=>{
    console.log('val',val);
    if(val?.order.time_range_b2c[0] === 600){
      second.value = 600;
      duration.value = 10;
      unit.value = 'm';
    }
  },{immediate:true,deep:true})
  
  return reactive({
    shortcutsLabel,
    duration,
    second,
    unit,
    increase,
    decrease,
    change,
    isCurrent,
  })
}
