<script setup lang="ts">
const poros = defineProps(['title','columns','dataSource'])
</script>

<template>
  <APopover placement="right" overlayClassName="deil-popover">
    <span class="detail">
      <slot>
        {{ $t("ZZVBd-Mz3zQR-2ZFcw0DJ") }}
      </slot>
    </span>
    <template #content>
      <div class="deil-title">{{ poros.title }}</div>
      <ATable
        class="ant-table-striped"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
      />
      <div class="h-[20px] opacity-0" />
    </template>
  </APopover>
</template>

<style scoped lang="scss">
.detail {
  --uno: 'text-[--highlight] mx-[5px] my-[0] cursor-pointer whitespace-nowrap';
}
.deil-title {
  --uno: 'text-center text-[15px] py-[20px] text-[#f6f6f6] bg-[#192241]';

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

</style>

<style lang="scss">
.deil-popover.deil-popover.deil-popover {
  .ant-table-thead{
    background: #202c52;
  }

  .deil-title {
    --uno: 'text-center text-[15px] py-[20px] text-[#f6f6f6] bg-[#192241]';

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  .ant-table-wrapper .ant-table-thead > tr > th{
    background: #202c52;
  }

  .ant-table-row{
    border-radius: 0;
  }

  .ant-table {
    --uno: 'bg-transparent text-[#c8d0df]';

    .ant-table-thead > tr > th {
      --uno: 'whitespace-nowrap border-none text-[#f6f6f6] px-[30px] py-[9px]';
    }

    .ant-table-tbody{
      background: #1a2241;

      > tr > td {
        --uno: 'border-none px-[30px] py-[7px]';
      }

      > tr.ant-table-row:hover > td {
        background: #202c52 !important;
        border-radius: none;
      }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      --uno: 'bg-transparent';
    }
  }

  // 箭头样式
  &.ant-popover-placement-right {
    --uno: 'pl-[15px]';
  }

  .ant-popover-arrow {
    --uno: 'before:scale-200 before:bg-[#1a2241]';
  }

  .ant-popover-inner-content {
    --uno: 'pl-[0] pr-[0] py-[0] rounded-[10px] text-[#c8d0df] bg-[#1a2241]';
  }

  .ant-popover-inner {
    --uno: 'bg-[rgba(1,2,18,_0.8)] [box-shadow:none] p-0';
  }

}
</style>
